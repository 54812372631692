import { useState } from "react";

const LazyImage = ({ src, alt, className = "" }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  if (error) {
    return (
      <div className={`${className} bg-gray-100 flex items-center justify-center`}>
        <span className="text-gray-400">Failed to load image</span>
      </div>
    );
  }

  return (
    <div className="">
      {!loaded && (
        <div
          className={`${className} animate-pulse bg-gray-200 absolute inset-0 border border-gray-300 flex items-center justify-center`}
          aria-hidden="true"
        />
      )}
      <img
        src={src}
        alt={alt || 'Product image'}
        className={`${className} ${loaded ? 'opacity-100' : 'opacity-0'} ${!loaded ? 'hidden' : ''}`}
        loading="lazy"
        onLoad={() => setLoaded(true)}
        onError={() => setError(true)}
      />
    </div>
  );
};

export default LazyImage;